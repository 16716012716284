import { Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import Titulo from "../../Titulo/Titulo";
import { SucursalInterface } from "../../../interfaces/SucursalInterface";

//estilos
const classes = require("./SucursalesDetalle.module.css").default;

const SucursalesDetalle = () => {

  const { idSucursal } = useParams();
  const { sendRequest } = useFetch();
  const [sucursal, setSucursal] = useState<SucursalInterface>();

  //Formulario
  const {
    reset,
    formState: { errors },
  } = useForm({mode: "onTouched"});

  //Función que trata el detalle de usuario.
  const transformData = useCallback(
    (variable: any) => {
      setSucursal(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el usuario a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/sucursal/${idSucursal}?includeEstado[eq]=true&includeTipoSucursal[eq]=true&includeContacto[eq]=true`,
      },
      transformData
    );
  }, [idSucursal, sendRequest, transformData]);

  return (
    <>
      <Titulo texto="Ficha de la tienda" tipo="cliente" />
      <Container className="mb-3">
        <Row className="mb-3">
          <div className="col-6">
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Nombre
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.nombre}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Región
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.region}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Marca
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.marca}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                CECO
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.ceco}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Dirección fiscal
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.direccion_fiscal}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Latitud
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.latitud}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Longitud
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.longitud}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Tipo
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.tipo_sucursal?.nombre}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Estado
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.estado?.nombre}
                />
              </div>
            </Form.Group>
          </div>
          <div className="col-6">
            <Row className="mb-3">
              <h2 className={classes.subtitulo}>Contacto</h2>
            </Row>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Nombre
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.contacto?.nombre}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Teléfono
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.contacto?.telefono}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Correo
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={sucursal?.contacto?.correo}
                />
              </div>
            </Form.Group>
            <div className="mb-3">
              <iframe
                src="https://www.google.com/maps/embed/v1/place?q=México,+CDMX,+México&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                width="100%"
                height="300"
              ></iframe>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SucursalesDetalle;
