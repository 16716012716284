import { Alert } from "react-bootstrap";

type Props = {
  onCloseHandler: () => void;
};

const ServerErrorMessage = ({ onCloseHandler }: Props) => {
  return (
    <Alert variant="danger" onClose={onCloseHandler} dismissible>
      <Alert.Heading>Error en el servidor</Alert.Heading>
      <p>
        El servidor está experimentando problemas. Contactar al administrador
        del servicio.
      </p>
    </Alert>
  );
};

export default ServerErrorMessage;
