import { Alert, Button, Container, Spinner } from "react-bootstrap";
import TimelineCard from "../../TimelineCard/TimelineCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { UsuarioInterface } from "../../../interfaces/UsuarioInterface";
import { AccionInterface } from "../../../interfaces/AccionInterface";
import { TipoAccionInterface } from "../../../interfaces/TipoAccionInterface";
import useFetch from "../../../hooks/useFetch";
//estilos
const classes = require("./Timeline.module.css").default;

interface AccionPorActivo extends AccionInterface {
  usuario: UsuarioInterface;
  tipo_accion: TipoAccionInterface;
}

const Timeline = () => {
  const { idActivo } = useParams();
  const navigate = useNavigate();
  const [acciones, setAcciones] = useState<any>();
  const { sendRequest } = useFetch();

  //Función que trata el detalle de usuario.
  const transformData = useCallback((variable: any) => {
    let accionesPorFecha: any = {};
    let prevFecha: string | null = null;
    variable.data.map((accion: AccionPorActivo) => {
      if (prevFecha !== accion.fecha.substring(0, 10)) {
        prevFecha = accion.fecha.substring(0, 10);
        accionesPorFecha[prevFecha] = [accion];
      } else {
        accionesPorFecha[prevFecha] = [...accionesPorFecha[prevFecha], accion];
      }
    });
    setAcciones(accionesPorFecha);
  }, []);

  //Se recibe el usuario a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/accion?activo_id[eq]=${idActivo}&includeUsuario=true&includeTipoAccion=true`,
      },
      transformData
    );
  }, [idActivo, sendRequest, transformData]);

  if (!acciones) {
    return <Spinner animation="grow" variant="info" />;
  }

  if (Object.keys(acciones).length === 0) {
    return (
      <Container>
        <Alert variant="success">
          <Alert.Heading>Sin historial por mostrar</Alert.Heading>
          <p>
            Este activo no tiene registrada ninguna acción. Lo que quiere decir
            que se ha registrado, pero no se ha utilizado, no se ha instalado un
            token, ni ha reportado alertas. No está asignado a alguna bodega o
            tienda.
          </p>
          <hr />
          <p className="mb-0">
            Si piensas que se trata de un error contacta al administrador del
            sistema.
          </p>
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <h2>Historial del activo</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          title="Programar mantenimiento"
          style={{ backgroundColor: "#21618C", borderColor: "white" }}
          onClick={() => navigate(`/main/mantenimientos/crear`)}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Programar mantenimiento
        </Button>
      </div>
      <section className={`${classes.timelineArea} section_padding_130`}>
        <div className="row">
          <div className="col-12">
            <div className="apland-timeline-area">
              {Object.entries(acciones).map(([key, accion]: any) => (
                <div className={classes.singleTimelineArea} key={key}>
                  <div
                    className={`${classes.timelineDate} wow fadeInLeft`}
                    data-wow-delay="0.1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <p>{key}</p>
                  </div>
                  <div className="row">
                    {accion.map((acc: any) => (
                      <TimelineCard
                        key={acc.id}
                        titulo={`${acc.tipo_accion.nombre}`}
                        descripcion={`${acc.usuario.nombre} ${
                          acc.usuario.apellido_paterno
                        } realizó la acción a las ${acc.fecha.substring(
                          10,
                          16
                        )}`}
                        icono={
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{
                              marginTop: "4px",
                              fontSize: "20px",
                              color: "white",
                            }}
                          />
                        }
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default Timeline;
