import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";
import ServerErrorMessage from "../../Mensajes/ServerErrorMessage";
import ServerMessage from "../../Mensajes/ServerMessage";

//estilos
const classes = require("./PermisosCrear.module.css").default;

const PermisosCrear = () => {
  const navigate = useNavigate();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();

  //Función que hace el POST para crear el usuario.
  const crearPermiso = (data: any) => {
    sendRequest(
      {
        url: `/permiso`,
        method: "POST",
        body: data,
      },
      (variable: any) => {
        toast.success("El permiso se ha registrado.", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/main/administrar/permisos");
      }
    );
  };

  //Validaciones del formulario
  const formSchema = Yup.object().shape({
    nombre: Yup.string()
      .required("El nombre es obligatorio")
      .max(200, "El nombre no puede ser mayor a 200 caracteres"),
  });

  //Formulario
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  return (
    <Container className="mb-3">
      {/** Error de autorizacion */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {error === 500 && (
        <ServerErrorMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      <h2 className={classes.subtitulo}>Datos del Permiso </h2>
      <Row className="mb-3">
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control {...register("nombre")} />
              {errors.nombre && (
                <Form.Text style={{ color: "red" }}>
                  {errors.nombre?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
      </Row>
      <Row className="mb-3">
        <div style={{ marginTop: "15px" }}>
          <Button
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            onClick={handleSubmit(crearPermiso)}
          >
            Guardar
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default PermisosCrear;
