import { useCallback, useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { RolInterface } from "../../../interfaces/RolInterface";
import { toast } from "react-toastify";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";
import ServerErrorMessage from "../../Mensajes/ServerErrorMessage";
import ServerMessage from "../../Mensajes/ServerMessage";

//interfaces
interface PermisosxRolInterface extends RolInterface {
  pivot?: { rol_id: number; permiso_id: number };
}

//estilos
const classes = require("./RolesAsignar.module.css").default;

const RolesAsignar = () => {
  const { idRol } = useParams();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const [permisos, setPermisos] = useState<PermisosxRolInterface[]>();
  const navigate = useNavigate();

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setPermisos(variable.data);
  }, []);

  //Función que trae los permisos por rol
  useEffect(() => {
    sendRequest(
      {
        url: "/permisos_rol",
        method: "POST",
        body: { rol_id: idRol },
      },
      transformData
    );
  }, [sendRequest, transformData, idRol]);

  //Actualizar permisos
  const guardarPermisos = (event: any) => {
    event.preventDefault();
    sendRequest(
      {
        url: "/savepermisos_rol",
        method: "POST",
        body: { rol_id: idRol, permisos: permisos },
      },
      () => {
        toast.success("Se asignaron correctamente los permisos.", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/main/administrar/roles");
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!permisos) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      {/** Error de autorizacion */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {error === 500 && (
        <ServerErrorMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      <h2 className={classes.subtitulo}>Lista de permisos</h2>
      <Form onSubmit={guardarPermisos}>
        {permisos.map((permiso, index) => (
          <div key={permiso.id} className="mb-3">
            <Form.Check
              type={"checkbox"}
              id={`default-checkbox`}
              label={permiso.nombre}
              style={{ textAlign: "left" }}
              defaultChecked={permiso.pivot ? true : false}
              onChange={(event) => {
                if (event.target.checked) {
                  permisos[index]["pivot"] = {
                    rol_id: +idRol!,
                    permiso_id: permiso.id,
                  };
                } else {
                  delete permisos[index]["pivot"];
                }
              }}
            />
          </div>
        ))}
        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
            marginBottom: "20px",
          }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default RolesAsignar;
