//librerías
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

import Logo from "../../img/alsea.svg";
import Caea from "../../img/caea.svg";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

//estilos
const classes = require("./Main.module.css").default;

/**
 * Screen Main
 * @description: Es la pantalla que contiene el nav de la aplicación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */

const Main = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/**Navbar */}
      <Navbar
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 2,
          backgroundColor: "white",
        }}
      >
        <Container>
          <Navbar.Brand href="/main">
            <img src={Logo} alt="" height={"40"} />
          </Navbar.Brand>

          <Nav className="justify-content-end flex-grow-1 pe-3">
            <NavDropdown
              title="Administración"
              id={`offcanvasNavbarDropdown-expand-sm`}
              className={classes.dropItem}
            >
              <Link
                className={classes.dropdownItem}
                to="/main/activos/listar"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                Activos
              </Link>
              <Link
                className={classes.dropdownItem}
                to="/main/alertas/listar"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                Alertas
              </Link>
              <Link
                className={classes.dropdownItem}
                to="/main/incidencias/listar"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                Incidencias
              </Link>
              <Link
                className={classes.dropdownItem}
                to="/main/administrar/usuarios"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                Usuarios
              </Link>
              <Link
                className={classes.dropdownItem}
                to="/main/administrar/bodegas"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                CEDIS
              </Link>
              <Link
                className={classes.dropdownItem}
                to="/main/administrar/tiendas"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                Tiendas
              </Link>
              <Link
                className={classes.dropdownItem}
                to="/main/administrar/tokens"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                Tokens
              </Link>
              <Link
                className={classes.dropdownItem}
                to="/main/administrar/tipo_activos"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                Tipos de activos
              </Link>
              {authContext.tokenCan("Rol:store") && (
                <Link
                  className={classes.dropdownItem}
                  to="/main/administrar/roles"
                  style={{ color: "var(--bs-dropdown-link-color)" }}
                >
                  Roles
                </Link>
              )}
              {authContext.tokenCan("Permiso:assign") && (
                <Link
                  className={classes.dropdownItem}
                  to="/main/administrar/permisos"
                  style={{ color: "var(--bs-dropdown-link-color)" }}
                >
                  Permisos
                </Link>
              )}
            </NavDropdown>
            <NavDropdown
              title="Inventario"
              id={`offcanvasNavbarDropdown-expand-sm`}
              className={classes.dropItem}
            >
              <Link
                className={classes.dropdownItem}
                to="/main/monitoreo-bodegas"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                CEDIS
              </Link>
              <Link
                className={classes.dropdownItem}
                to="/main/monitoreo-sucursales"
                style={{ color: "var(--bs-dropdown-link-color)" }}
              >
                Tiendas
              </Link>
            </NavDropdown>
          </Nav>
          <button className={classes.cerrarsesion} onClick={authContext.logout}>
            Cerrar sesión
          </button>
        </Container>
      </Navbar>
      <img
        src={require("../../img/banner.png")}
        width="100%"
        height="270"
        alt=""
        style={{ marginTop: "66px" }}
      />
      <img
        src={require("../../img/titulo_marcas.png")}
        width="600px"
        height="115px"
        alt=""
        style={{ marginTop: "-40px", marginLeft: "500px" }}
      />
      <Outlet />
      <div style={{ backgroundColor: "#0d5475", marginBottom: -30 }}>
        <Container style={{ backgroundColor: "#0d5475" }}>
          <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <div className="col-md-4 d-flex align-items-center">
              <a
                className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
                href="https://skyangel.com.mx/"
              >
                <img
                  src={require("../../img/skyangel.png")}
                  width="50px"
                  height="50px"
                  alt=""
                />
              </a>
              <span className="mb-3 mb-md-0" style={{ color: "white" }}>
                by Skyangel©
              </span>
            </div>
            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3">
                <span className="mb-3 mb-md-0" style={{ color: "white" }}>
                  En caso de emergencia <strong>800 25 25 723</strong>
                </span>
                <a
                  className="text-muted"
                  href="https://www.caea.es/"
                  style={{ marginLeft: 10 }}
                >
                  <img src={Caea} alt="" height={"40"} />
                </a>
              </li>
            </ul>
          </footer>
        </Container>
      </div>
    </>
  );
};

export default Main;
