import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertaInterface } from "../../interfaces/AlertaInterface";
import { useState } from "react";
import useFetch from "../../hooks/useFetch";
interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
  title: string;
  alerta: AlertaInterface | undefined;
  updateAlertas: () => void;
}

/**
 * ExcelModal Component
 * @description: Permite establecer filtros de la informacion del excel a descargar.
 * @date 06/03/2023.
 * @param Props origin que es de que formulario se manda a llamar,  showModal que es una variable de estado
 * que define si se muestra el modal o no, handleCloseModal es la funcion que cierra el modal. handleLoadingSymbol permite
 * manipular una variablle de estado del componente que lo llama (origin) para mostrar el simbolo de carga.
 * @returns JSX del Modal.
 */

const CustomModal = ({
  showModal,
  handleCloseModal,
  title,
  alerta,
  updateAlertas,
}: Props) => {
  const { sendRequest } = useFetch();
  const [descripcion, setDescripcion] = useState("");

  const atenderAlerta = () => {
    sendRequest(
      {
        url: `/alerta/${alerta?.id}`,
        method: "DELETE",
        body: {
          descripcion,
        },
      },
      (variable: any) => {
        updateAlertas();
      }
    );
  };

  const enviarFormulario = () => {
    setDescripcion("");
    handleCloseModal();
    atenderAlerta();
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Asunto</Form.Label>
              <Form.Control
                type="text"
                value={alerta ? alerta?.incidencia?.nombre : ""}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                value={descripcion}
                onChange={(event) => setDescripcion(event.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col>
              <Button
                variant="secondary"
                style={{ float: "right" }}
                onClick={handleCloseModal}
              >
                Cerrar
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  backgroundColor: "#21618C",
                  borderColor: "white",
                }}
                onClick={enviarFormulario}
              >
                <FontAwesomeIcon icon={faCheck} /> Atender
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
