import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, Spinner } from "react-bootstrap";
import CustomTable from "../../Formularios/CustomTable/CustomTable";
import {
  faSquarePlus,
  faEye,
  faToggleOff,
  faToggleOn,
  faPenNib,
  faBell,
  faHammer,
} from "@fortawesome/free-solid-svg-icons";

//context
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { ActivoInterface } from "../../../interfaces/ActivoInterface";

const ActivosSucursalTable = () => {
  const { idSucursal } = useParams();
  const navigate = useNavigate();
  const { sendRequest } = useFetch();
  const [activos, setActivos] = useState<ActivoInterface[]>();

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setActivos(variable.data);
  }, []);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: `/activo/sucursal/${idSucursal}?includeSucursal=true`,
      },
      transformData
    );
  }, [sendRequest, transformData, idSucursal]);

  //Función que desactiva a un usuario.
  const changeActivoStatus = useCallback(
    (idActivo: number) => {
      if (
        window.confirm(
          "¿Seguro que desea cambiar el estatus del activo? NO será posible monitorearlo"
        )
      ) {
        sendRequest(
          {
            url: `/activo/${idActivo}`,
            method: "DELETE",
          },
          (variable: any) => {
            setActivos(undefined);
            toast.success("El activo ha cambiado de estatus.", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            sendRequest(
              {
                url: "/activo",
              },
              transformData
            );
          }
        );
      }
    },
    [sendRequest, transformData]
  );

  const columns = useMemo<ColumnDef<ActivoInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "CECO",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.sucursal?.ceco ? row.sucursal?.ceco : ""),
      },
      {
        accessorKey: "nombre",
        header: () => "Nombre",
      },
      {
        accessorKey: "marca",
        header: () => "Marca",
      },
      {
        accessorKey: "modelo",
        header: () => "Modelo",
      },
      {
        accessorKey: "serie",
        header: () => "Serie",
      },
      {
        accessorKey: "inventario",
        header: () => "Inventario",
      },
      {
        accessorKey: "sucursal_id",
        header: () => "Sucursal",
      },
      {
        accessorKey: "token_actual",
        header: () => "Token",
      },
      {
        header: "Estatus",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.estatus ? "Activo" : "Inactivo"),
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Ver detalle"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(`/main/activos/detalle/${info.row.original.id}`)
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button
              title="Editar"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(`/main/activos/editar/${info.row.original.id}`)
              }
            >
              <FontAwesomeIcon icon={faPenNib} />
            </Button>
            <Button
              title={
                info.row.original.estatus
                  ? "Deshabilitar activo"
                  : "Habilitar activo"
              }
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() => changeActivoStatus(info.row.original.id)}
            >
              <FontAwesomeIcon
                icon={info.row.original.estatus ? faToggleOn : faToggleOff}
              />
            </Button>
            <Button
              title="Alertas"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(`/main/alertas/activo/${info.row.original.id}`)
              }
            >
              <FontAwesomeIcon icon={faBell} />
            </Button>
            <Button
              title="Mantenimientos"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(`/main/mantenimientos/activo/${info.row.original.id}`)
              }
            >
              <FontAwesomeIcon icon={faHammer} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, changeActivoStatus]
  );

  if (!activos) {
    return <Spinner animation="grow" variant="info" />;
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          title="Agregar un nuevo activo"
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
          }}
          onClick={() => navigate(`/main/activos/crear`)}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Crear activo
        </Button>
      </div>
      <CustomTable
        {...{
          titulo: "Activos",
          data: activos,
          columns,
        }}
      />
    </Container>
  );
};

export default ActivosSucursalTable;
