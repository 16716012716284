import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { useState } from "react";
import { Form, Pagination, Table } from "react-bootstrap";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Estilos
const classes = require("./CustomTable.module.css").default;

// Funcion que define las busquedas por columna.
function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: ReactTable<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  if (column.id === "fecha") {
    return (
      <Form.Control
        type="date"
        value={(columnFilterValue ?? "") as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
      />
    );
  }

  return typeof firstValue === "number" ? (
    <Form.Control
      type="number"
      value={(columnFilterValue as [number, number])?.[0] ?? ""}
      onChange={(e) =>
        column.setFilterValue((old: [number, number]) => [
          e.target.value,
          e.target.value,
        ])
      }
    />
  ) : (
    // <div>
    //   {/** Si es numerico se coloca un input de valor minimo */}
    //   <Form.Control
    //     type="number"
    //     value={(columnFilterValue as [number, number])?.[0] ?? ""}
    //     onChange={(e) =>
    //       column.setFilterValue((old: [number, number]) => [
    //         e.target.value,
    //         old?.[1],
    //       ])
    //     }
    //     placeholder={`Min`}
    //   />
    //   {/** Si el dato es numerico se coloca un input de valor maximo */}
    //   <Form.Control
    //     type="number"
    //     value={(columnFilterValue as [number, number])?.[1] ?? ""}
    //     onChange={(e) =>
    //       column.setFilterValue((old: [number, number]) => [
    //         old?.[0],
    //         e.target.value,
    //       ])
    //     }
    //     placeholder={`Max`}
    //   />
    // </div>
    <Form.Control
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Buscar...`}
    />
  );
}

/**
 * CustomTable Component
 * @description: Dibuja una tabla para paginarla, ordenarla y filtrarla.
 * @date 26/01/2023.
 * @param Props Recibe data (los datos de la tabla) y columns que es el formato de las columnas.
 * @returns JSX de la tabla.
 */

const CustomTable = ({
  data,
  columns,
}: {
  data: any[];
  columns: ColumnDef<any>[];
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //modo de ejeucicon (debug o produccion)
    debugTable: false,
  });

  return (
    <div className="card shadow-2-strong">
      <div className="table-responsive">
        <Table hover className="rounded rounded-3 overflow-hidden">
          <thead className={classes.cabecera}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={classes.alignMiddle}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div>
                          {/**Nombre de las colunas con la opcion de ordenamiento */}
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <FontAwesomeIcon icon={faArrowUp} />,
                              desc: <FontAwesomeIcon icon={faArrowDown} />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {/**Inputs para el filtrado de las columnas  */}
                          <div>
                            {header.column.getCanFilter() ? (
                              <div>
                                <Filter column={header.column} table={table} />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {/** Renderiza la info de cada renglon */}
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={`${row.id} ${cell.id}`}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>

        {/** Coloca los inputs de la paginacion */}
        <div className="d-flex flex-row justify-content-around">
          <Pagination>
            <Pagination.First
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            />
            <Pagination.Prev
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            />
            <Pagination.Next
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />
            <Pagination.Last
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            />
          </Pagination>
          <div className="d-flex flex-row align-items-center">
            <p>
              Página {table.getState().pagination.pageIndex + 1} de{" "}
              {table.getPageCount()}
            </p>
          </div>
          <Form.Select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            style={{ width: "20%" }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
