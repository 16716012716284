import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, Spinner } from "react-bootstrap";
import CustomTable from "../../Formularios/CustomTable/CustomTable";
import {
  faSquarePlus,
  faPenNib,
} from "@fortawesome/free-solid-svg-icons";

//context
import useFetch from "../../../hooks/useFetch";
import { MantenimientoInterface } from '../../../interfaces/Mantenimiento';

const MantenimientosTable = () => {
  const { idActivo } = useParams();
  const navigate = useNavigate();
  const { sendRequest } = useFetch();
  const [mantenimientos, setMantenimientos] = useState<MantenimientoInterface[]>();

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setMantenimientos(variable.data);
  }, []);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: `/mantenimiento/activo/${idActivo}?includeActivo[eq]=true`,
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const columns = useMemo<ColumnDef<MantenimientoInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "fecha_inicio",
        header: () => "Fecha de inicio",
      },
      {
        accessorKey: "fecha_estimada_fin",
        header: () => "Fecha estimada de fin",
      },
      {
        accessorKey: "problema",
        header: () => "Problema",
      },
      {
        accessorKey: "tecnico",
        header: () => "Técnico",
      },
      {
        accessorKey: "telefono_tecnico",
        header: () => "Teléfono del técnico",
      },
      {
        header: "Activo",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.activo ? row.activo.nombre : ""),
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Editar"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/mantenimientos/editar/${info.row.original.id}`
                )
              }
            >
              <FontAwesomeIcon icon={faPenNib} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate]
  );

  if (!mantenimientos) {
    return <Spinner animation="grow" variant="info" />;
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          title="Agregar un nuevo mantenimiento"
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
          }}
          onClick={() => navigate(`/main/mantenimientos/crear/${idActivo}`)}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Crear mantenimiento
        </Button>
      </div>
      <CustomTable
        {...{
          titulo: "Mantenimientos",
          data: mantenimientos,
          columns,
        }}
      />
    </Container>
  );
};

export default MantenimientosTable;