import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, Spinner } from "react-bootstrap";
import CustomTable from "../../Formularios/CustomTable/CustomTable";
import { faSquarePlus, faPenNib } from "@fortawesome/free-solid-svg-icons";

//context
import useFetch from "../../../hooks/useFetch";
import { PermisoInterface } from "../../../interfaces/PermisoInterface";

const PermisosTable = () => {
  const navigate = useNavigate();
  const { sendRequest } = useFetch();
  const [permisos, setPermisos] = useState<PermisoInterface[]>();

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setPermisos(variable.data);
  }, []);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/permiso",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const columns = useMemo<ColumnDef<PermisoInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "nombre",
        header: () => "Nombre",
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Editar"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/administrar/permisos/editar/${info.row.original.id}`
                )
              }
            >
              <FontAwesomeIcon icon={faPenNib} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate]
  );

  if (!permisos) {
    return <Spinner animation="grow" variant="info" />;
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          title="Agregar un nuevo permiso"
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
          }}
          onClick={() => navigate(`/main/administrar/permisos/crear`)}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Agregar permiso
        </Button>
      </div>
      <CustomTable
        {...{
          titulo: "Permisos",
          data: permisos,
          columns,
        }}
      />
    </Container>
  );
};

export default PermisosTable;
