import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container } from "react-bootstrap";
import CustomTable from "../../Formularios/CustomTable/CustomTable";
import { faSquarePlus, faEye } from "@fortawesome/free-solid-svg-icons";

//context
import { AuthContext } from "../../../context/AuthContext";
import SucursalCard from "../../SucursalCard/SucursalCard";

export interface Sucursal {
  id: number;
  nombre: string;
  direccion: string;
  telefono: string;
  representante: string;
  contrato: string;
}
const data = [
  {
    id: 1,
    nombre: "Sucursal",
    direccion: "Río Danubio 87, Cuauhtémoc, 06500 Ciudad de México, CDMX",
    telefono: "2245 6799",
    representante: "Agustín Robles Melgar",
    contrato: "CF-45642344",
  },
  {
    id: 2,
    nombre: "Sucursal 2",
    direccion: "Río Guadalquivir 9, Cuauhtémoc, 06500 Ciudad de México, CDMX",
    telefono: "1145 6799",
    representante: "Diana Robles Melgar",
    contrato: "CF-45642333",
  },
];

const SucursalesTable = () => {
  const authContext = useContext(AuthContext);
  const [sucursales, setSucursales] = useState<Sucursal[]>(data);
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<Sucursal>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "nombre",
        header: () => "Nombre comercial",
      },
      {
        accessorKey: "direccion",
        header: () => "Dirección fiscal",
      },
      {
        accessorKey: "telefono",
        header: () => "Teléfono",
      },
      {
        accessorKey: "representante",
        header: () => "Representante",
      },
      {
        accessorKey: "contrato",
        header: () => "Contrato",
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Ver detalle"
              style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
              onClick={() => navigate(`/main/sucursales/detalle/${info.row.original.id}`)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, authContext]
  );

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          title="Agregar un nuevo activo"
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
            marginTop: "10px",
          }}
          onClick={() => navigate(`/main/sucursales/crear`)}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Crear tienda
        </Button>
      </div>
      <CustomTable
        {...{
          titulo: "Tiendas",
          data: sucursales,
          columns,
        }}
      />
    </Container>
  );
};

export default SucursalesTable;
