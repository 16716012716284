import { Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import Titulo from "../../Titulo/Titulo";
import { ActivoInterface } from "../../../interfaces/ActivoInterface";

//estilos
const classes = require("./ActivosDetalle.module.css").default;

const ActivosDetalle = () => {

  const { idActivo } = useParams();
  const { sendRequest } = useFetch();
  const [activo, setActivo] = useState<ActivoInterface>();

  //Formulario
  const {
    reset,
    formState: { errors },
  } = useForm({mode: "onTouched"});

  //Función que trata el detalle de usuario.
  const transformData = useCallback(
    (variable: any) => {
      setActivo(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el usuario a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/activo/${idActivo}?includeToken[eq]=true&includeSucursal[eq]=true`,
      },
      transformData
    );
  }, [idActivo, sendRequest, transformData]);

  return (
    <>
      <Titulo texto="Ficha técnica" tipo="cliente" />
      <Container className="mb-3">
        <Row className="mb-3">
          <div className="col-6">
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Nombre
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.nombre}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Marca
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.marca}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Serie
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.serie}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Dimensiones
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.dimsensiones}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Descripción
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.descripcion}
                  as="textarea"
                />
              </div>
            </Form.Group>
            <Row className="mb-3">
              <h2 className={classes.subtitulo}>Sucursal</h2>
            </Row>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Nombre
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.sucursal?.nombre}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Región
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.sucursal?.region}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                CECO
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.sucursal?.ceco}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Marca
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.sucursal?.marca}
                />
              </div>
            </Form.Group>
           
          </div>
          <div className="col-6">
            <Row className="mb-3">
              <h2 className={classes.subtitulo}>Token</h2>
            </Row>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Nombre
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.token?.nombre}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                IMEI
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={activo?.token?.imei}
                />
              </div>
            </Form.Group>
            <div className="mb-3">
              <iframe
                src="https://www.google.com/maps/embed/v1/place?q=México,+CDMX,+México&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                width="100%"
                height="300"
              ></iframe>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ActivosDetalle;
