import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { EstadoInterface } from "../../../interfaces/EstadoInterface";
import useFetch from "../../../hooks/useFetch";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";
import ServerErrorMessage from "../../Mensajes/ServerErrorMessage";
import ServerMessage from "../../Mensajes/ServerMessage";

//estilos
const classes = require("./BodegasCrear.module.css").default;

const BodegasCrear = () => {
  const navigate = useNavigate();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const [estados, setEstados] = useState<EstadoInterface[]>([]);

  useEffect(() => {
    sendRequest(
      {
        url: `/estado`,
      },
      (variable: any) => {
        setEstados(
          variable.data.map((estado: EstadoInterface) => {
            return { value: estado.id, label: estado.nombre };
          })
        );
      }
    );
  }, [sendRequest]);

  //Función que hace el POST para crear el usuario.
  const crearBodega = (data: any) => {
    sendRequest(
      {
        url: `/sucursal`,
        method: "POST",
        body: { ...data, tipo_sucursal_id: 1 },
      },
      (variable: any) => {
        toast.success("El CEDIS se ha registrado.", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/main/administrar/bodegas");
      }
    );
  };

  //Validaciones del formulario
  const formSchema = Yup.object().shape({
    nombre: Yup.string()
      .required("El nombre es obligatorio")
      .max(200, "El nombre no puede ser mayor a 200 caracteres"),
    region: Yup.string()
      .required("La region es obligatoria")
      .max(200, "La region no puede ser mayor a 200 caracteres"),
    ceco: Yup.string()
      .nullable()
      .optional()
      .max(30, "El CECO no puede ser mayor a 30 caracteres"),
    direccion_fiscal: Yup.string()
      .nullable()
      .optional()
      .max(150, "La dirección fiscal no puede ser mayor a 150 caracteres"),
    latitud: Yup.string()
      .nullable()
      .optional()
      .max(30, "La latitud no puede ser mayor a 30 caracteres"),
    longitud: Yup.string()
      .nullable()
      .optional()
      .max(30, "La longitud no puede ser mayor a 30 caracteres"),
    estado: Yup.object().required("Es necesario seleccionar un estado"),
    marca: Yup.string()
      .required("La marca es obligatoria")
      .max(150, "El representante legal no puede ser mayor a 150 caracteres"),
    contacto_nombre: Yup.string()
      .required("Es necesario agregar un contacto")
      .max(
        150,
        "El nombre del representante no puede ser mayor a 150 caracteres"
      ),
    correo_contacto: Yup.string()
      .nullable()
      .optional()
      .matches(
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "El correo debe tener formato {usuario}@{dominio}.{sufijo}"
      )
      .max(150, "El correo no puede ser mayor a 150 caracteres"),
    telefono_contacto: Yup.string()
      .nullable()
      .optional()
      .matches(/^[^A-z]*$/, "El telefono no puede contener letras")
      .max(30, "El teléfono no puede ser mayor a 30 caracteres"),
    distrital_nombre: Yup.string()
      .required("Es necesario agregar un contacto")
      .max(
        150,
        "El nombre del representante no puede ser mayor a 150 caracteres"
      ),
    distrital_correo: Yup.string()
      .nullable()
      .optional()
      .matches(
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "El correo debe tener formato {usuario}@{dominio}.{sufijo}"
      )
      .max(150, "El correo no puede ser mayor a 150 caracteres"),
    distrital_telefono: Yup.string()
      .nullable()
      .optional()
      .matches(/^[^A-z]*$/, "El telefono no puede contener letras")
      .max(30, "El teléfono no puede ser mayor a 30 caracteres"),
  });

  //Formulario
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  return (
    <Container className="mb-3">
      {/** Error de autorizacion */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {error === 500 && (
        <ServerErrorMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      <h2 className={classes.subtitulo}>Datos del CEDIS </h2>
      <Row className="mb-3">
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control {...register("nombre")} />
              {errors.nombre && (
                <Form.Text style={{ color: "red" }}>
                  {errors.nombre?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Región</Form.Label>
              <Form.Control {...register("region")} />
              {errors.region && (
                <Form.Text style={{ color: "red" }}>
                  {errors.region?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>CECO</Form.Label>
              <Form.Control {...register("ceco")} />
              {errors.ceco && (
                <Form.Text style={{ color: "red" }}>
                  {errors.ceco?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Dirección fiscal</Form.Label>
              <Form.Control {...register("direccion_fiscal")} />
              {errors.direccion_fiscal && (
                <Form.Text style={{ color: "red" }}>
                  {errors.direccion_fiscal?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Latitud</Form.Label>
              <Form.Control
                type="number"
                step={0.00000000001}
                min={-90}
                max={90}
                {...register("latitud")}
              />
              {errors.latitud && (
                <Form.Text style={{ color: "red" }}>
                  {errors.latitud?.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Longitud</Form.Label>
              <Form.Control
                type="number"
                step={0.00000000001}
                min={-180}
                max={180}
                {...register("longitud")}
              />
              {errors.longitud && (
                <Form.Text style={{ color: "red" }}>
                  {errors.longitud?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Estado</Form.Label>
              <Controller
                name="estado"
                control={control}
                render={({ field }) => <Select {...field} options={estados} />}
              />
              {errors.estado && (
                <Form.Text style={{ color: "red" }}>
                  {errors.estado?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Marca</Form.Label>
              <Form.Control {...register("marca")} />
              {errors.marca && (
                <Form.Text style={{ color: "red" }}>
                  {errors.marca?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nombre del representante</Form.Label>
              <Form.Control {...register("contacto_nombre")} />
              {errors.contacto_nombre && (
                <Form.Text style={{ color: "red" }}>
                  {errors.contacto_nombre?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Correo del contacto</Form.Label>
              <Form.Control {...register("correo_contacto")} />
              {errors.correo_contacto && (
                <Form.Text style={{ color: "red" }}>
                  {errors.correo_contacto?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Telefono del contacto</Form.Label>
              <Form.Control {...register("telefono_contacto")} />
              {errors.telefono_contacto && (
                <Form.Text style={{ color: "red" }}>
                  {errors.telefono_contacto?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nombre del distrital</Form.Label>
              <Form.Control {...register("distrital_nombre")} />
              {errors.distrital_nombre && (
                <Form.Text style={{ color: "red" }}>
                  {errors.distrital_nombre?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Correo del distrital</Form.Label>
              <Form.Control {...register("distrital_correo")} />
              {errors.distrital_correo && (
                <Form.Text style={{ color: "red" }}>
                  {errors.distrital_correo?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Telefono del distrital</Form.Label>
              <Form.Control {...register("distrital_telefono")} />
              {errors.distrital_telefono && (
                <Form.Text style={{ color: "red" }}>
                  {errors.distrital_telefono?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
      </Row>
      <Row className="mb-3">
        <div style={{ marginTop: "15px" }}>
          <Button
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            onClick={handleSubmit(crearBodega)}
          >
            Guardar
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default BodegasCrear;
