import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, Spinner } from "react-bootstrap";
import CustomTable from "../../Formularios/CustomTable/CustomTable";
import {
  faSquarePlus,
  faToggleOff,
  faToggleOn,
  faPenNib,
} from "@fortawesome/free-solid-svg-icons";

//context
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { TipoActivoInterface } from "../../../interfaces/TipoActivoInterface";

const TipoActivosTable = () => {
  const navigate = useNavigate();
  const { sendRequest } = useFetch();
  const [tipoActivos, setTipoActivos] = useState<TipoActivoInterface[]>();

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setTipoActivos(variable.data);
  }, []);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/tipo_activo",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  //Función que desactiva a un usuario.
  const changeTipoActivoStatus = useCallback(
    (idToken: number) => {
      if (
        window.confirm(
          "¿Seguro que desea cambiar el estatus este tipo de activo? NO será posible utilizarlo en este estado"
        )
      ) {
        sendRequest(
          {
            url: `/tipo_activo/${idToken}`,
            method: "DELETE",
          },
          (variable: any) => {
            setTipoActivos(undefined);
            toast.success("El tipo de activo ha cambiado de estatus.", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            sendRequest(
              {
                url: "/tipo_activo",
              },
              transformData
            );
          }
        );
      }
    },
    [sendRequest, transformData]
  );

  const columns = useMemo<ColumnDef<TipoActivoInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "nombre",
        header: () => "Nombre",
      },
      {
        header: "Estatus",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.estatus ? "Activo" : "Inactivo"),
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Editar"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/administrar/tipo_activos/editar/${info.row.original.id}`
                )
              }
            >
              <FontAwesomeIcon icon={faPenNib} />
            </Button>
            <Button
              title={
                info.row.original.estatus
                  ? "Deshabilitar tipo activo"
                  : "Habilitar tipo activo"
              }
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() => changeTipoActivoStatus(info.row.original.id)}
            >
              <FontAwesomeIcon
                icon={info.row.original.estatus ? faToggleOn : faToggleOff}
              />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, changeTipoActivoStatus]
  );

  if (!tipoActivos) {
    return <Spinner animation="grow" variant="info" />;
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          title="Agregar un nuevo token"
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
          }}
          onClick={() => navigate(`/main/administrar/tipo_activos/crear`)}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Agregar tipo de activo
        </Button>
      </div>
      <CustomTable
        {...{
          titulo: "Tipo de activo",
          data: tipoActivos,
          columns,
        }}
      />
    </Container>
  );
};

export default TipoActivosTable;
