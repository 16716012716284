//librerías
import { Routes, Route, Navigate } from "react-router-dom";

//componentes
import Login from "../Login/Login";
import Main from "../Main/Main";
import Home from "../Home/Home";
import Servicios from "../Servicios/Servicios";
import Administracion from "../Administracion/Administracion";
import Usuarios from "../Administracion/Usuarios/Usuarios";
import UsuariosTable from "../../components/Usuarios/UsuariosTable";

import Mantenimientos from "../Mantenimientos/Mantenimientos";
import ResetPassword from "../ResetPassword/ResetPassword";

import Alertas from "../../components/Servicios/ServiciosTable/Alertas";
import Timeline from "../../components/Activos/Timeline/Timeline";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

//Estilos
import "./App.css";
import BodegasDetalle from "../../components/Bodegas/BodegasDetalle/BodegasDetalle";
import Bodegas from "../Administracion/Bodegas/Bodegas";
import BodegasTable from "../../components/Bodegas/BodegasTable/BodegasTable";
import BodegasCrear from "../../components/Bodegas/BodegasCrear/BodegasCrear";
import BodegasEditar from "../../components/Bodegas/BodegasEditar/BodegasEditar";

import Tiendas from "../Administracion/Tiendas/Tiendas";
import TiendasTable from "../../components/Tiendas/TiendasTable/TiendasTable";
import TiendasCrear from "../../components/Tiendas/TiendasCrear/TiendasCrear";
import TiendasEditar from "../../components/Tiendas/TiendasEditar/TiendasEditar";

import Tokens from "../Administracion/Tokens/Tokens";
import TokensTable from "../../components/Tokens/TokensTable/TokensTable";
import TokensCrear from "../../components/Tokens/TokensCrear/TokensCrear";
import TokensEditar from "../../components/Tokens/TokensEditar/TokensEditar";

import Roles from "../Administracion/Roles/Roles";
import RolesTable from "../../components/Roles/RolesTable/RolesTable";
import RolesCrear from "../../components/Roles/RolesCrear/RolesCrear";
import RolesEditar from "../../components/Roles/RolesEditar/RolesEditar";
import RolesAsignar from "../../components/Roles/RolesAsignar/RolesAsignar";

import Permisos from "../Administracion/Permisos/Permisos";
import PermisosTable from "../../components/Permisos/PermisosTable/PermisosTable";
import PermisosCrear from "../../components/Permisos/PermisosCrear/PermisosCrear";
import PermisosEditar from "../../components/Permisos/PermisosEditar/PermisosEditar";

import SucursalesCrear from "../../components/Sucursales/SucursalesCrear/SucursalesCrear";
import SucursalesDetalle from "../../components/Sucursales/SucursalesDetalle/SucursalesDetalle";
import Sucursales from "../Sucursales/Sucursales";
import Activos from "../Activos/Activos";
import SucursalesTable from "../../components/Sucursales/SucursalesTable/SucursalesTable";

import MonitoreoSucursales from "../../components/Sucursales/MonitoreoSucursales/MonitoreoSucursales";
import MonitoreoBodegas from "../../components/Bodegas/MonitoreoBodegas/MonitoreoBodegas";
import ActivosTable from "../../components/Activos/ActivosTable/ActivosTable";
import ActivosCrear from "../../components/Activos/ActivosCrear/ActivosCrear";
import ActivosDetalle from "../../components/Activos/ActivosDetalle/ActivosDetalle";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import UsuarioCrear from "../../components/Usuarios/UsuarioCrear/UsuarioCrear";
import UsuarioEditar from "../../components/Usuarios/UsuarioEditar/UsuarioEditar";
import ActivosEditar from "../../components/Activos/ActivosEditar/ActivosEditar";
import ActivosSucursalTable from "../../components/Activos/ActivosSucursalTable/ActivosSucursalTable";
import AlertasActivoTable from "../../components/Alertas/AlertasActivoTable/AlertasActivoTable";
import AlertasTable from "../../components/Alertas/AlertasTable/AlertasTable";
import AlertasDetalle from "../../components/Alertas/AlertasDetalle/AlertasDetalle";
import Incidencias from "../Incidencias/Incidencias";
import IncidenciasTable from "../../components/Incidencias/IncidenciasTable/IncidenciasTable";
import IncidenciasCrear from "../../components/Incidencias/IncidenciasCrear/IncidenciasCrear";
import IncidenciasEditar from "../../components/Incidencias/IncidenciasEditar/IncidenciasEditar";
import MantenimientosTable from "../../components/Mantenimientos/MantenimientosTable/MantenimientosTable";
import MantenimientosEditar from "../../components/Mantenimientos/MantenimientosEditar/MantenimientosEditar";
import MantenimientosCrear from "../../components/Mantenimientos/MantenimientosCrear/MantenimientosCrear";
import TipoActivos from "../TipoActivos/TipoActivos";
import TipoActivosTable from "../../components/TipoActivos/TipoActivosTable/TipoActivosTable";
import TipoActivosCrear from "../../components/TipoActivos/TipoActivosCrear/TipoActivosCrear";
import TipoActivoEditar from "../../components/TipoActivos/TipoActivosEditar/TipoActivosEditar";

/**
 * Screen App
 * @description: Es la pantalla que cubre a la App y que tiene definidos
 * los routes de navegación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */

const App = () => {
  const authContext = useContext(AuthContext);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Routes>
          {!authContext.isLoggedIn && (
            <>
              <Route path="/" element={<Login />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
          {authContext.isLoggedIn && (
            <>
              <Route path="*" element={<Navigate to="/main" />} />
              <Route path="/main" element={<Main />}>
                <Route index element={<Home />} />
                <Route
                  index
                  path="monitoreo-sucursales"
                  element={<MonitoreoSucursales />}
                />
                <Route
                  path="monitoreo-bodegas"
                  element={<MonitoreoBodegas />}
                />
                <Route path="alertas" element={<Servicios />}>
                  <Route path="listar" element={<AlertasTable />} />
                  <Route
                    path="activo/:idActivo"
                    element={<AlertasActivoTable />}
                  />
                  <Route
                    path="detalle/:idAlerta"
                    element={<AlertasDetalle />}
                  />
                </Route>
                <Route path="incidencias" element={<Incidencias />}>
                  <Route path="listar" element={<IncidenciasTable />} />
                  <Route path="crear" element={<IncidenciasCrear />} />
                  <Route
                    path="editar/:idIncidencia"
                    element={<IncidenciasEditar />}
                  />
                </Route>
                <Route path="mantenimientos" element={<Mantenimientos />}>
                  <Route
                    path="activo/:idActivo"
                    element={<MantenimientosTable />}
                  />
                  <Route
                    path="crear/:idActivo"
                    element={<MantenimientosCrear />}
                  />
                  <Route
                    path="editar/:idMantenimiento"
                    element={<MantenimientosEditar />}
                  />
                </Route>
                <Route path="sucursales" element={<Sucursales />}>
                  <Route path="crear" element={<SucursalesCrear />} />
                  <Route path="listar" element={<SucursalesTable />} />
                  <Route
                    path="detalle/:idSucursal"
                    element={<SucursalesDetalle />}
                  />
                </Route>
                <Route path="activos" element={<Activos />}>
                  <Route path="crear" element={<ActivosCrear />} />
                  <Route path="listar" element={<ActivosTable />} />
                  <Route
                    path="sucursal/:idSucursal"
                    element={<ActivosSucursalTable />}
                  />
                  <Route path="editar/:idActivo" element={<ActivosEditar />} />
                  <Route
                    path="detalle/:idActivo"
                    element={<ActivosDetalle />}
                  />
                  <Route path="timeline/:idActivo" element={<Timeline />} />
                </Route>
                <Route path="administrar" element={<Administracion />}>
                  <Route path="usuarios" element={<Usuarios />}>
                    <Route index element={<UsuariosTable />} />
                    <Route path="crear" element={<UsuarioCrear />} />
                    <Route path="edit/:idUsuario" element={<UsuarioEditar />} />
                  </Route>
                  <Route path="bodegas" element={<Bodegas />}>
                    <Route index element={<BodegasTable />} />
                    <Route path="crear" element={<BodegasCrear />} />
                    <Route
                      path="editar/:idBodega"
                      element={<BodegasEditar />}
                    />
                  </Route>
                  <Route path="tiendas" element={<Tiendas />}>
                    <Route index element={<TiendasTable />} />
                    <Route path="crear" element={<TiendasCrear />} />
                    <Route
                      path="editar/:idTienda"
                      element={<TiendasEditar />}
                    />
                  </Route>
                  <Route path="tokens" element={<Tokens />}>
                    <Route index element={<TokensTable />} />
                    <Route path="crear" element={<TokensCrear />} />
                    <Route path="editar/:idToken" element={<TokensEditar />} />
                  </Route>
                  <Route path="tipo_activos" element={<TipoActivos />}>
                    <Route index element={<TipoActivosTable />} />
                    <Route path="crear" element={<TipoActivosCrear />} />
                    <Route
                      path="editar/:idTipoActivo"
                      element={<TipoActivoEditar />}
                    />
                  </Route>
                  <Route path="roles" element={<Roles />}>
                    <Route index element={<RolesTable />} />
                    <Route path="crear" element={<RolesCrear />} />
                    <Route path="editar/:idRol" element={<RolesEditar />} />
                    <Route path="asignar/:idRol" element={<RolesAsignar />} />
                  </Route>
                  <Route path="permisos" element={<Permisos />}>
                    <Route index element={<PermisosTable />} />
                    <Route path="crear" element={<PermisosCrear />} />
                    <Route
                      path="editar/:idPermiso"
                      element={<PermisosEditar />}
                    />
                  </Route>
                </Route>
              </Route>
            </>
          )}
        </Routes>
        <ToastContainer limit={1} />
      </div>
    </QueryClientProvider>
  );
};

export default App;
