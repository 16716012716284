//Hooks
import { toast } from "react-toastify";
import { useContext, useRef, useState } from "react";
import { useMutationData } from "../../hooks/useMutationData";
import { AuthContext } from "../../context/AuthContext";

import { API } from "../../global/global";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

//estilos
const classes = require("./Login.module.css").default;

/**
 * Screen Login
 * @description: Es la pantalla para iniciar sesión.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */
const Login = () => {
  const [loading, setLoading] = useState(false);

  const mutateLogin = useMutationData("/login", "post");
  const correoInputRef = useRef<HTMLInputElement>(null);
  const contrasenaInputRef = useRef<HTMLInputElement>(null);
  const authContext = useContext(AuthContext);

  toast.clearWaitingQueue();

  //Función que hace el POST para crear la unidad.
  const login = async (data: any) => {
    setLoading(true);
    try {
      const loginData = await mutateLogin.mutateAsync(data);
      authContext.login(
        loginData["data"].usuario,
        loginData["data"].token,
        loginData["data"].permisos
      );
    } catch (error) {
      toast.error("Correo o contraseña incorrectos", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  };

  let correo = "";

  //Correo para restaurar contrasena
  const requestPasswordReset = async () => {
    let body = { correo: correo };
    try {
      const response = await fetch(API + "/forgot-password", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        toast.success("Se envió el correo para resetear contraseña ", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.info(
          "Si el correo existe en breve recibirá un correo para cambiar su contraseña",
          {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } catch (e) {
      toast.info(
        "Si el correo existe en breve recibirá un correo para cambiar su contraseña",
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    setLoading(false);
  };

  return (
    <div className={classes.maincontainer}>
      {loading && <Spinner animation="grow" variant="info" />}
      {!loading && (
        <div className={classes.main}>
          <input
            className={classes.chk}
            type="checkbox"
            id="chk"
            aria-hidden="true"
          />
          {/** Formulario para iniciar sesión */}
          <div className={classes.signup}>
            <div className={classes.div}>
              <img
                className={classes.cogs}
                src={require("../../img/alsea.png")}
                alt="Mountain"
                width={"100"}
              />
            </div>
            <label className={classes.label} htmlFor="chk" aria-hidden="true">
              Sistema de Control de Activos Alsea
            </label>
            <input
              className={classes.input}
              name="correo"
              placeholder="Correo"
              ref={correoInputRef}
              required
            />
            <input
              className={classes.input}
              type="password"
              name="contrasena"
              placeholder="Contraseña"
              ref={contrasenaInputRef}
              required
            />
            <button
              className={classes.button}
              onClick={() => {
                login({
                  correo: correoInputRef.current?.value,
                  contrasena: contrasenaInputRef.current?.value,
                });
              }}
            >
              Iniciar sesión
            </button>
          </div>
          {/** Formulario para registrarse */}
          <div className={classes.login}>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                setLoading(true);
                requestPasswordReset();
              }}
            >
              <label className={classes.label} htmlFor="chk" aria-hidden="true">
                Recuperar contraseña
              </label>
              <input
                className={classes.inputregister}
                type="email"
                name="email"
                onChange={(value) => (correo = value.target.value)}
                placeholder="Correo electrónico"
                autoComplete="off"
                required
              />
              {loading && <p>Cargando...</p>}
              <button type="submit" className={classes.button}>
                Enviar correo
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
