import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//estilos
const classes = require("./ServiciosCrear.module.css").default;

const SucursalesCrear = () => {
  const navigate = useNavigate();

  //Formulario
  const {
    register,
    formState: { errors },
  } = useForm();

  return (
    <Container className="mb-3">
      <Row className="mb-3">
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Número de la sucursal</Form.Label>
              <Form.Control {...register("id", { maxLength: 200 })} />
              {errors.id && (
                <Form.Text style={{ color: "red" }}>
                  Número de la sucursal no puede ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nombre comercial</Form.Label>
              <Form.Control {...register("nombre", { maxLength: 200 })} />
              {errors.nombre && (
                <Form.Text style={{ color: "red" }}>
                  Nombre no puede ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Dirección fiscal</Form.Label>
              <Form.Control {...register("direccion", { maxLength: 200 })} />
              {errors.direccion && (
                <Form.Text style={{ color: "red" }}>
                  Dirección no puede ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control {...register("telefono", { maxLength: 200 })} />
              {errors.telefono && (
                <Form.Text style={{ color: "red" }}>
                  Dirección no puede ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Representación legal</Form.Label>
              <Form.Control
                {...register("representante", { maxLength: 200 })}
              />
              {errors.representante && (
                <Form.Text style={{ color: "red" }}>
                  Representación legal no puede ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Contacto</Form.Label>
              <Form.Control {...register("contacto", { maxLength: 200 })} />
              {errors.contacto && (
                <Form.Text style={{ color: "red" }}>
                  Contacto no puede ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Dirección del contacto</Form.Label>
              <Form.Control
                {...register("direccion_contacto", { maxLength: 200 })}
              />
              {errors.direccion_contacto && (
                <Form.Text style={{ color: "red" }}>
                  Dirección del contacto no puede ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Notas</Form.Label>
              <Form.Control
                {...register("notas", { maxLength: 200 })}
                as="textarea"
              />
              {errors.notas && (
                <Form.Text style={{ color: "red" }}>
                  Notas no pueden ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
      </Row>
      <Row className="mb-3">
        <div style={{ marginTop: "15px" }}>
          <Button
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            onClick={() => navigate(`/main/sucursales/listar`)}
          >
            Guardar
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default SucursalesCrear;
