import { Outlet } from "react-router-dom";

const Bodegas = () => {
  return (
    <div style={{ marginTop: "10px" }}>
      <Outlet />
    </div>
  );
};

export default Bodegas;
