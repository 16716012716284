/**
 * Screen Home
 * @description: Es la pantalla principal de la aplicación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */
import { Button, Container } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../../components/Formularios/CustomTable/CustomTable";
import { ColumnDef } from "@tanstack/react-table";

//iconos
import Tienda from "../../img/sucursal.svg";
import ActivoL from "../../img/activo.svg";
import { ActivoInterface } from "../../interfaces/ActivoInterface";
import useFetch from "../../hooks/useFetch";
import { SucursalInterface } from "../../interfaces/SucursalInterface";
import { TokenInterface } from "../../interfaces/TokenInterface";

interface ActivoWithSucursal extends ActivoInterface {
  sucursal: SucursalInterface;
  token: TokenInterface;
}

const Home = () => {
  const [activos, setActivos] = useState<ActivoWithSucursal[]>([]);
  const { sendRequest } = useFetch();
  const navigate = useNavigate();

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setActivos(variable.data);
  }, []);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/activo?includeSucursal=true&includeToken=true",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const columns = useMemo<ColumnDef<ActivoWithSucursal>[]>(
    () => [
      {
        header: "",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        header: "CECO",
        cell: (info) => info.getValue(),
        accessorFn: (row) => row.sucursal.ceco,
      },
      {
        header: "Marca",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.sucursal.marca ? row.sucursal.marca : ""),
      },
      {
        header: "Nombre de la tienda",
        cell: (info) => info.getValue(),
        accessorFn: (row) => row.sucursal.nombre,
      },
      {
        header: "Token",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.token ? row.token.nombre : ""),
      },
      {
        accessorKey: "serie",
        header: () => "No. de serie",
      },
      {
        header: "Estado",
        cell: (info) => info.getValue(),
        accessorFn: (row) =>
          row.sucursal && row.sucursal.estado ? row.sucursal.estado.nombre : "",
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Ficha técnica"
              style={{
                backgroundColor: "rgba(0,0,0,0.0)",
                borderColor: "rgba(0,0,0,0.0)",
              }}
              onClick={() =>
                navigate(`/main/activos/detalle/${info.row.original.id}`)
              }
            >
              <img src={ActivoL} alt="" height={"30"} />
            </Button>
            <Button
              title="Ficha de la tienda"
              style={{
                backgroundColor: "rgba(0,0,0,0.0)",
                borderColor: "rgba(0,0,0,0.0)",
              }}
              onClick={() =>
                navigate(
                  `/main/sucursales/detalle/${info.row.original.sucursal_id}`
                )
              }
            >
              <img src={Tienda} alt="" height={"30"} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Ubicación",
        cell: (info) => info.getValue(),
        accessorFn: (row) =>
          row.sucursal.direccion_fiscal
            ? `${row.sucursal.region} ${row.sucursal.direccion_fiscal}`
            : row.sucursal.region,
      },
      {
        header: "Estatus",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.estatus ? "Activo" : "Inactivo"),
      },
    ],
    [navigate]
  );

  return (
    <div style={{ marginTop: "10px" }}>
      <Container>
        <CustomTable
          {...{
            data: activos,
            columns,
          }}
        />
      </Container>
    </div>
  );
};

export default Home;
