import { faSquare } from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  Badge,
  Button,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//components
import AlertCard from "../AlertCard/AlertCard";
import { useNavigate } from "react-router-dom";
import { SucursalInterface } from "../../interfaces/SucursalInterface";
import { AlertaInterface } from "../../interfaces/AlertaInterface";
import { ActivoInterface } from "../../interfaces/ActivoInterface";
import React, { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import CustomModal from "../CustomModal/CustomModal";

//styles
const classes = require("./SucursalCard.module.css").default;

interface AlertasSucursalInterface extends AlertaInterface {
  activo: ActivoInterface;
}

type Props = {
  sucursal: SucursalInterface;
  eventKey: string;
  sucursalesConAlertas: number[];
  refetch: Function;
};

interface AlertasSucursalInterface extends AlertaInterface {
  activo: ActivoInterface;
}

const SucursalCard = ({
  sucursal,
  eventKey,
  sucursalesConAlertas,
  refetch,
}: Props) => {
  const navigate = useNavigate();
  const [alertas, setAlertas] = useState<AlertasSucursalInterface[]>();
  const { sendRequest } = useFetch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [globalAlerta, setGlobalAlerta] = useState<AlertaInterface>();

  const closeModal = () => {
    setShowModal(false);
  };

  const getAlertas = () => {
    setAlertas(undefined);
    sendRequest(
      {
        url: "/sucursal_alertas",
        method: "POST",
        body: {
          sucursal_id: sucursal.id,
        },
      },
      (variable: any) => {
        setAlertas(variable.data);
      }
    );
  };

  const atiendeAlertas = useCallback(() => {
    setAlertas(
      alertas?.filter(
        (alerta: AlertaInterface) => alerta.id !== globalAlerta?.id
      )
    );
    refetch();
  }, [alertas, refetch, globalAlerta]);

  return (
    <Accordion.Item eventKey={eventKey} onClick={getAlertas}>
      <Accordion.Header>
        <FontAwesomeIcon
          icon={faSquare}
          style={{
            fontSize: "35px",
            marginRight: "20px",
            color: sucursalesConAlertas.find(
              (element) => element === sucursal.id
            )
              ? "#BF1616"
              : "#1484b4",
          }}
          className={
            sucursalesConAlertas.find((element) => element === sucursal.id)
              ? classes.animationPulse
              : ""
          }
        />
        <div>
          <strong className="d-block text-gray-dark">
            {sucursal.nombre}
            <Badge bg="dark" style={{ marginLeft: 10 }}>
              {sucursal.ceco}
            </Badge>
          </strong>
          <p className="small">{`${sucursal.marca} ${sucursal.region}`}</p>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div
          className="media text-muted pt-3"
          style={{
            display: !alertas || alertas.length === 0 ? "block" : "flex",
            flexWrap: "wrap",
          }}
        >
          {!alertas && <Spinner animation="grow" variant="info" />}
          {alertas &&
            alertas?.length > 0 &&
            alertas.map((alerta: AlertasSucursalInterface) => (
              <AlertCard
                alerta={alerta}
                key={`alerta${alerta.id}`}
                openModal={() => setShowModal(true)}
                setGlobalAlerta={(id: AlertaInterface) =>
                  setGlobalAlerta(alerta)
                }
              />
            ))}
          {alertas && alertas.length === 0 && (
            <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
              <strong className="d-block text-gray-dark">
                Ninguna alerta por atender
              </strong>
            </p>
          )}
        </div>
        <br />
        <ButtonGroup>
          <Button
            id="tbg-check-2"
            variant="outline-secondary"
            onClick={() => navigate(`/main/activos/sucursal/${sucursal.id}`)}
          >
            Ver activos
          </Button>
        </ButtonGroup>
      </Accordion.Body>
      <CustomModal
        title="Agregar incidencia"
        alerta={globalAlerta}
        showModal={showModal}
        handleCloseModal={closeModal}
        updateAlertas={atiendeAlertas}
      />
    </Accordion.Item>
  );
};

export default React.memo(SucursalCard);
