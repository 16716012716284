import { Button, Card } from "react-bootstrap";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertaInterface } from "../../interfaces/AlertaInterface";
import { ActivoInterface } from "../../interfaces/ActivoInterface";

interface AlertasSucursalInterface extends AlertaInterface {
  activo: ActivoInterface;
}

type Props = {
  alerta: AlertasSucursalInterface;
  openModal: () => void;
  setGlobalAlerta: (alerta: AlertaInterface) => void;
};
const AlertCard = ({ alerta, openModal, setGlobalAlerta }: Props) => {
  const abrirFormulario = (id: number) => {
    openModal();
    setGlobalAlerta(alerta);
  };
  return (
    <Card style={{ flex: "1 0 21%", margin: "5px" }}>
      <Card.Header>{alerta.incidencia?.nombre}</Card.Header>
      <Card.Body>
        <Card.Title>
          <FontAwesomeIcon icon={faGlobe} size="2x" />
        </Card.Title>
        <Card.Text>{alerta.activo.nombre}</Card.Text>
        <Card.Text>{alerta.activo.marca}</Card.Text>
        <Button
          variant="outline-primary"
          onClick={() => abrirFormulario(alerta.id)}
        >
          Atender
        </Button>
      </Card.Body>
    </Card>
  );
};

export default AlertCard;
