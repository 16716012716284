import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import useFetch from "../../../hooks/useFetch";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";
import ServerErrorMessage from "../../Mensajes/ServerErrorMessage";
import ServerMessage from "../../Mensajes/ServerMessage";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { RolInterface } from "../../../interfaces/RolInterface";
import { UsuarioInterface } from "../../../context/AuthContext";

//estilos
const classes = require("./UsuarioEditar.module.css").default;

const UsuarioEditar = () => {
  const navigate = useNavigate();
  const { idUsuario } = useParams();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const [roles, setRoles] = useState<RolInterface[]>([]);
  const [usuario, setUsuario] = useState<UsuarioInterface>();

  useEffect(() => {
    sendRequest(
      {
        url: `/rol`,
      },
      (variable: any) => {
        setRoles(
          variable.data.map((rol: RolInterface) => {
            return { value: rol.id, label: rol.nombre };
          })
        );
      }
    );
  }, [sendRequest]);

  //Función que hace el POST para crear el usuario.
  const editarUsuario = (data: any) => {
    sendRequest(
      {
        url: `/usuario/${idUsuario}`,
        method: "PATCH",
        body: data,
      },
      (variable: any) => {
        toast.success("El usuario se ha actualizado.", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/main/administrar/usuarios");
      }
    );
  };

  //Validaciones del formulario
  const formSchema = Yup.object().shape({
    nombre: Yup.string()
      .required("El nombre es obligatorio")
      .min(1, "El nombre mínimo debe tener dos caracteres")
      .max(50, "El nombre no puede ser mayor a 50 caracteres"),
    apellido_paterno: Yup.string()
      .required("El apellido es obligatorio")
      .min(1, "El apellido mínimo debe tener dos caracteres")
      .max(50, "El apellido no puede ser mayor a 50 caracteres"),
    apellido_materno: Yup.string().nullable().optional(),
    correo: Yup.string()
      .required("El correo es obligatorio")
      .matches(
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "El correo debe tener formato {usuario}@{dominio}.{sufijo}"
      )
      .max(100, "El correo no puede ser mayor a 100 caracteres"),
    rol: Yup.object().required("Es necesario seleccionar un rol"),
  });

  //Formulario
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  //Función que trata el detalle de usuario.
  const transformData = useCallback(
    (variable: any) => {
      setUsuario(variable.data);
      reset({
        ...variable.data,
        rol: { value: variable.data.rol.id, label: variable.data.rol.nombre },
      });
    },
    [reset]
  );

  //Se recibe el usuario a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/usuario/${idUsuario}?includeRol=true`,
      },
      transformData
    );
  }, [idUsuario, sendRequest, transformData]);

  if (roles.length === 0 && !usuario) {
    return <Spinner animation="grow" variant="info" />;
  }

  return (
    <Container className="mb-3">
      {/** Error de autorizacion */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {error === 500 && (
        <ServerErrorMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      <h2 className={classes.subtitulo}>Datos del usuario </h2>
      <Row className="mb-3">
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control {...register("nombre")} />
              {errors.nombre && (
                <Form.Text style={{ color: "red" }}>
                  {errors.nombre?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Apellido Paterno</Form.Label>
              <Form.Control {...register("apellido_paterno")} />
              {errors.apellido_paterno && (
                <Form.Text style={{ color: "red" }}>
                  {errors.apellido_paterno?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Apellido Materno</Form.Label>
              <Form.Control
                {...register("apellido_materno", { maxLength: 50 })}
              />
              {errors.apellido_materno && (
                <Form.Text style={{ color: "red" }}>
                  {errors.apellido_materno?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="email"
                {...register("correo")}
                autoComplete="one-time-code"
              />
              {errors.correo && (
                <Form.Text style={{ color: "red" }}>
                  {errors.correo?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Rol</Form.Label>
              <Controller
                name="rol"
                control={control}
                render={({ field }) => <Select {...field} options={roles} />}
              />
              {errors.rol && (
                <Form.Text style={{ color: "red" }}>
                  {errors.rol?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
      </Row>
      <Row className="mb-3">
        <div style={{ marginTop: "15px" }}>
          <Button
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            onClick={handleSubmit(editarUsuario)}
          >
            Registrar
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default UsuarioEditar;
