import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import useDebouncedValue from "../../hooks/useDebouncedValue";

interface Props {
  onDebounce: (value: string) => void;
}

/**
 * SearchInput Component
 * @description: Componente que obtiene el valor de un input y retrasa la búsqueda
 * con un Debouncer.
 * @date 17/08/2022.
 * @param Props contiene los estilos (styles) y el texto a buscar (onDebounce).
 * @returns JSX del Search Input.
 */

const SearchInput = ({ onDebounce }: Props) => {
  const [textValue, setTextValue] = useState("");
  const debouncedValue = useDebouncedValue(textValue);

  //Se retrasa el analisis del texto.
  useEffect(() => {
    onDebounce(debouncedValue);
  }, [debouncedValue, onDebounce]);

  return (
    <div>
      <div>
        <Form.Control
          placeholder="Filtrar..."
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchInput;
