import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";
import ServerErrorMessage from "../../Mensajes/ServerErrorMessage";
import ServerMessage from "../../Mensajes/ServerMessage";
import { MantenimientoInterface } from "../../../interfaces/Mantenimiento";

//estilos
const classes = require("./MantenimientosEditar.module.css").default;

const MantenimientosEditar = () => {
  const navigate = useNavigate();
  const { idMantenimiento } = useParams();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const [mantenimiento, setMantenimiento] = useState<MantenimientoInterface>();

  //Función que hace el POST para crear un mantenimiento.
  const editarMantenimiento = (data: any) => {
    sendRequest(
      {
        url: `/mantenimiento/${idMantenimiento}`,
        method: "PATCH",
        body: data,
      },
      (variable: any) => {
        toast.success("El mantenimiento se ha actualizado.", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(`/main/mantenimientos/activo/${mantenimiento?.activo_id}`);
      }
    );
  };

  //Validaciones del formulario
  const formSchema = Yup.object().shape({
    fecha_inicio: Yup.date()
      .required("La fecha de inicio es obligatoria")
      .transform((curr, orig) => (orig === "" ? null : curr)),
    fecha_estimada_fin: Yup.date()
      .required("La fecha estimada de fin es obligatoria")
      .transform((curr, orig) => (orig === "" ? null : curr))
      .min(
        Yup.ref("fecha_inicio"),
        ({ min }) => `La fecha tiene que ser posterior a ${min}`
      ),
    fecha_fin: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .min(
        Yup.ref("fecha_inicio"),
        ({ min }) => `La fecha tiene que ser posterior a ${min}`
      ),
    problema: Yup.string()
      .required("El problema es obligatorio")
      .max(150, "El problema no puede ser mayor a 255 caracteres"),
    tecnico: Yup.string()
      .required("El técnico es obligatorio")
      .max(150, "El técnico no puede ser mayor a 255 caracteres"),
    telefono_tecnico: Yup.string()
      .required("El teléfono del técnico es obligatorio")
      .max(150, "El teléfono del técnico no puede ser mayor a 20 caracteres"),
  });

  //Formulario
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  //Función que trata el detalle de usuario.
  const transformData = useCallback(
    (variable: any) => {
      setMantenimiento(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el usuario a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/mantenimiento/${idMantenimiento}`,
      },
      transformData
    );
  }, [idMantenimiento, sendRequest, transformData]);

  return (
    <Container className="mb-3">
      {/** Error de autorizacion */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {error === 500 && (
        <ServerErrorMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      <h2 className={classes.subtitulo}>Datos del mantenimiento </h2>
      <Row className="mb-3">
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Fecha de inicio</Form.Label>
              <Form.Control {...register("fecha_inicio")} type="date" />
              {errors.fecha_inicio && (
                <Form.Text style={{ color: "red" }}>
                  {errors.fecha_inicio?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Fecha estimada de fin</Form.Label>
              <Form.Control {...register("fecha_estimada_fin")} type="date" />
              {errors.fecha_estimada_fin && (
                <Form.Text style={{ color: "red" }}>
                  {errors.fecha_estimada_fin?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Fecha de fin</Form.Label>
              <Form.Control {...register("fecha_fin")} type="date" />
              {errors.fecha_fin && (
                <Form.Text style={{ color: "red" }}>
                  {errors.fecha_fin?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Problema</Form.Label>
              <Form.Control {...register("problema")} />
              {errors.problema && (
                <Form.Text style={{ color: "red" }}>
                  {errors.problema?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Técnico</Form.Label>
              <Form.Control {...register("tecnico")} />
              {errors.tecnico && (
                <Form.Text style={{ color: "red" }}>
                  {errors.tecnico?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Teléfono del técnico</Form.Label>
              <Form.Control {...register("telefono_tecnico")} />
              {errors.telefono_tecnico && (
                <Form.Text style={{ color: "red" }}>
                  {errors.telefono_tecnico?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
      </Row>
      <Row className="mb-3">
        <div style={{ marginTop: "15px" }}>
          <Button
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            onClick={handleSubmit(editarMantenimiento)}
          >
            Guardar
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default MantenimientosEditar;
