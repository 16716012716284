import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../hooks/useFetch";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";
import ServerErrorMessage from "../../Mensajes/ServerErrorMessage";
import ServerMessage from "../../Mensajes/ServerMessage";
import { SucursalInterface } from "../../../interfaces/SucursalInterface";
import { TokenInterface } from "../../../interfaces/TokenInterface";
import { TipoActivoInterface } from "../../../interfaces/TipoActivoInterface";

//estilos
const classes = require("./ActivosCrear.module.css").default;

const ActivosCrear = () => {
  const navigate = useNavigate();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const [sucursales, setSucursales] = useState<SucursalInterface[]>([]);
  const [tokens, setTokens] = useState<TokenInterface[]>([]);
  const [tiposActivo, setTiposActivo] = useState<TipoActivoInterface[]>([]);

  useEffect(() => {
    sendRequest(
      {
        url: `/sucursal`,
      },
      (variable: any) => {
        setSucursales(
          variable.data.map((sucursal: SucursalInterface) => {
            return { value: sucursal.id, label: sucursal.nombre };
          })
        );
      }
    );

    sendRequest(
      {
        url: `/token`,
      },
      (variable: any) => {
        setTokens(
          variable.data.map((token: TokenInterface) => {
            return { value: token.id, label: token.nombre };
          })
        );
      }
    );

    sendRequest(
      {
        url: `/tipo_activo`,
      },
      (variable: any) => {
        setTiposActivo(
          variable.data.map((tipoActivo: TipoActivoInterface) => {
            return { value: tipoActivo.id, label: tipoActivo.nombre };
          })
        );
      }
    );
  }, [sendRequest]);

  //Función que hace el POST para crear el usuario.
  const creaActivo = (data: any) => {
    sendRequest(
      {
        url: `/activo`,
        method: "POST",
        body: { ...data },
      },
      (variable: any) => {
        toast.success("El activo se ha registrado.", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/main/activos/listar");
      }
    );
  };

  //Validaciones del formulario
  const formSchema = Yup.object().shape({
    nombre: Yup.string()
      .required("El nombre es obligatorio")
      .max(150, "El nombre no puede ser mayor a 150 caracteres"),
    marca: Yup.string()
      .nullable()
      .optional()
      .max(50, "La marca no puede ser mayor a 50 caracteres"),
    modelo: Yup.string()
      .nullable()
      .optional()
      .max(80, "El modelo no puede ser mayor a 80 caracteres"),
    serie: Yup.string()
      .nullable()
      .optional()
      .max(20, "La serie no puede ser mayor a 20 caracteres"),
    dimensiones: Yup.string()
      .nullable()
      .optional()
      .max(30, "Las dimensiones no puede ser mayor a 30 caracteres"),
    descripcion: Yup.string()
      .nullable()
      .optional()
      .max(300, "La descripción no puede ser mayor a 300 caracteres"),
    inventario: Yup.string()
      .nullable()
      .optional()
      .max(20, "El inventario no puede ser mayor a 20 caracteres"),
    sucursal_id: Yup.object().required(
      "Es necesario seleccionar un una sucursal/bodega"
    ),
    tipo_activo_id: Yup.object().required(
      "Es necesario seleccionar un tipo de activo"
    ),
    token_actual: Yup.object().required("Es necesario seleccionar un token"),
  });

  //Formulario
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  return (
    <Container className="mb-3">
      {/** Error de autorizacion */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {error === 500 && (
        <ServerErrorMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      <h2 className={classes.subtitulo}>Datos del activo </h2>
      <Row className="mb-3">
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control {...register("nombre")} />
              {errors.nombre && (
                <Form.Text style={{ color: "red" }}>
                  {errors.nombre?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Marca</Form.Label>
              <Form.Control {...register("marca")} />
              {errors.marca && (
                <Form.Text style={{ color: "red" }}>
                  {errors.marca?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Modelo</Form.Label>
              <Form.Control {...register("modelo")} />
              {errors.modelo && (
                <Form.Text style={{ color: "red" }}>
                  {errors.modelo?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Serie</Form.Label>
              <Form.Control {...register("serie")} />
              {errors.serie && (
                <Form.Text style={{ color: "red" }}>
                  {errors.serie?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Dimensiones</Form.Label>
              <Form.Control {...register("dimensiones")} />
              {errors.dimensiones && (
                <Form.Text style={{ color: "red" }}>
                  {errors.dimensiones?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
        <div className="col-6">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Descripción</Form.Label>
              <Form.Control {...register("descripcion")} as="textarea" />
              {errors.descripcion && (
                <Form.Text style={{ color: "red" }}>
                  {errors.descripcion?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Inventario</Form.Label>
              <Form.Control {...register("inventario")} />
              {errors.inventario && (
                <Form.Text style={{ color: "red" }}>
                  {errors.inventario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Sucursal/Bodega</Form.Label>
              <Controller
                name="sucursal_id"
                control={control}
                render={({ field }) => (
                  <Select {...field} options={sucursales} />
                )}
              />
              {errors.sucursal_id && (
                <Form.Text style={{ color: "red" }}>
                  {errors.sucursal_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Token</Form.Label>
              <Controller
                name="token_actual"
                control={control}
                render={({ field }) => <Select {...field} options={tokens} />}
              />
              {errors.token_actual && (
                <Form.Text style={{ color: "red" }}>
                  {errors.token_actual?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Tipo de activo</Form.Label>
              <Controller
                name="tipo_activo_id"
                control={control}
                render={({ field }) => (
                  <Select {...field} options={tiposActivo} />
                )}
              />
              {errors.tipo_activo_id && (
                <Form.Text style={{ color: "red" }}>
                  {errors.tipo_activo_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
      </Row>
      <Row className="mb-3">
        <div style={{ marginTop: "15px" }}>
          <Button
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            onClick={handleSubmit(creaActivo)}
          >
            Guardar
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default ActivosCrear;
