import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";
import ServerErrorMessage from "../../Mensajes/ServerErrorMessage";
import ServerMessage from "../../Mensajes/ServerMessage";
import { IncidenciaInterface } from "../../../interfaces/IncidenciaInterface";

//estilos
const classes = require("./IncidenciasEditar.module.css").default;

const IncidenciasEditar = () => {
  const navigate = useNavigate();
  const { idIncidencia } = useParams();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const [incidencia, setIncidencia] = useState<IncidenciaInterface>();

  //Función que hace el POST para crear el usuario.
  const editarIncidencia = (data: any) => {
    sendRequest(
      {
        url: `/incidencia/${idIncidencia}`,
        method: "PATCH",
        body: data,
      },
      (variable: any) => {
        toast.success("La incidencia se ha actualizado.", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/main/incidencias/listar");
      }
    );
  };

   //Validaciones del formulario
   const formSchema = Yup.object().shape({
    nombre: Yup.string()
      .required("El nombre es obligatorio")
  });

  //Formulario
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  //Función que trata el detalle de usuario.
  const transformData = useCallback(
    (variable: any) => {
      setIncidencia(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el usuario a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/incidencia/${idIncidencia}`,
      },
      transformData
    );
  }, [idIncidencia, sendRequest, transformData]);

  return (
    <Container className="mb-3">
      {/** Error de autorizacion */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {error === 500 && (
        <ServerErrorMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      <h2 className={classes.subtitulo}>Datos de la incidencia</h2>
      <Row className="mb-3">
        <div className="col-12">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control {...register("nombre")} />
              {errors.nombre && (
                <Form.Text style={{ color: "red" }}>
                  {errors.nombre?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
        </div>
      </Row>
      <Row className="mb-3">
        <div style={{ marginTop: "15px" }}>
          <Button
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            onClick={handleSubmit(editarIncidencia)}
          >
            Guardar
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default IncidenciasEditar;
