import { Outlet } from "react-router-dom";

const Activos = () => {
  return (
    <div style={{ marginTop: "10px" }}>
      <Outlet />
    </div>
  );
};

export default Activos;
