import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, Spinner } from "react-bootstrap";
import CustomTable from "../../Formularios/CustomTable/CustomTable";
import { faEye } from "@fortawesome/free-solid-svg-icons";

//context
import useFetch from "../../../hooks/useFetch";
import { AlertaInterface } from "../../../interfaces/AlertaInterface";

const AlertasActivoTable = () => {
  const { idActivo } = useParams();
  const navigate = useNavigate();
  const { sendRequest } = useFetch();
  const [alertas, setAlertas] = useState<AlertaInterface[]>();

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setAlertas(variable.data);
  }, []);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: `/alerta/activo/${idActivo}?includeActivo[eq]=true&includeUsuario[eq]=true&includeIncidencia[eq]=true`,
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const columns = useMemo<ColumnDef<AlertaInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "descripcion",
        header: () => "Descripción",
      },
      {
        header: "Estatus",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.atendido ? "Atendida" : "Pendidente"),
      },
      {
        header: "Activo",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.activo ? row.activo.nombre : ""),
      },
      {
        header: "Incidencia",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.incidencia ? row.incidencia.nombre : ""),
      },
      {
        header: "Usuario",
        cell: (info) => info.getValue(),
        accessorFn: (row) =>
          row.usuario
            ? `${row.usuario.nombre} ${row.usuario.apellido_paterno}`
            : "",
      },
      {
        accessorKey: "fecha",
        header: () => "Fecha",
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Ver detalle"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(`/main/alertas/detalle/${info.row.original.id}`)
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate]
  );

  if (!alertas) {
    return <Spinner animation="grow" variant="info" />;
  }

  return (
    <Container>
      <CustomTable
        {...{
          titulo: "Alertas",
          data: alertas,
          columns,
        }}
      />
    </Container>
  );
};

export default AlertasActivoTable;
