import React from "react";
import { Container, Row } from "react-bootstrap";

//estilos
const classes = require("./Titulo.module.css").default;

type Props = {
  texto: string;
  tipo: string;
};

const Titulo = ({ texto, tipo }: Props) => {
  return (
    <Container fluid>
      <Row>
        <div className={`col-6 ${classes.noPaddingInline}`}>
          <h2 className={classes.titulo}> {texto} </h2>
        </div>
        <div className={`col-6 ${classes.noPaddingInline}`}>
          <img
            className={classes.imagen}
            src={tipo === "cliente" ? require("../../img/cliente.png") : require("../../img/cliente.png")}
            alt="cliente"
          />
        </div>
      </Row>
    </Container>
  );
};

export default Titulo;
