import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  faSquarePlus,
  faUserEdit,
  faUserPlus,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, Spinner } from "react-bootstrap";
import CustomTable from "../Formularios/CustomTable/CustomTable";

//interface
import { UsuarioInterface } from "../../interfaces/UsuarioInterface";

//context
import useFetch from "../../hooks/useFetch";
import { toast } from "react-toastify";

const UsuariosTable = () => {
  const { sendRequest } = useFetch();
  const [usuarios, setUsuarios] = useState<UsuarioInterface[]>([]);
  const navigate = useNavigate();

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setUsuarios(variable.data);
  }, []);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/usuario?includeRol=true",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  //Función que desactiva a un usuario.
  const changeUserStatus = useCallback(
    (idUsuario: number) => {
      if (window.confirm("¿Seguro que desea cambiar el estatus del usuario?")) {
        sendRequest(
          {
            url: `/usuario/${idUsuario}`,
            method: "DELETE",
          },
          (variable: any) => {
            setUsuarios([]);
            toast.success("El usuario ha cambiado de estatus.", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            sendRequest(
              {
                url: "/usuario?includeRol=true",
              },
              transformData
            );
          }
        );
      }
    },
    [sendRequest, transformData]
  );

  const columns = useMemo<ColumnDef<UsuarioInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "nombre",
        header: () => "Nombre",
      },
      {
        accessorKey: "apellido_paterno",
        header: () => "Apellido Paterno",
      },
      {
        header: "Apellido Materno",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.apellido_materno ? row.apellido_materno : ""),
      },
      {
        accessorKey: "correo",
        header: () => "Correo",
      },
      {
        header: "Rol",
        cell: (info) => info.getValue(),
        accessorFn: (row) => row.rol!.nombre,
      },
      {
        header: "Estatus",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.estatus ? "Activo" : "Inactivo"),
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Editar"
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/administrar/usuarios/edit/${info.row.original.id}`
                )
              }
            >
              <FontAwesomeIcon icon={faUserEdit} />
            </Button>
            <Button
              title={
                info.row.original.estatus
                  ? "Deshabilitar usuario"
                  : "Habilitar usuario"
              }
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() => changeUserStatus(info.row.original.id)}
            >
              <FontAwesomeIcon
                icon={info.row.original.estatus ? faUserSlash : faUserPlus}
              />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, changeUserStatus]
  );

  if (usuarios.length === 0) {
    return <Spinner animation="grow" variant="info" />;
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          title="Agregar un nuevo activo"
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
            marginTop: "10px",
          }}
          onClick={() => navigate(`/main/administrar/usuarios/crear`)}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Agregar Usuario
        </Button>
      </div>
      <CustomTable
        {...{
          titulo: "Usuarios",
          data: usuarios,
          columns,
        }}
      />
    </Container>
  );
};

export default UsuariosTable;
