import { Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import Titulo from "../../Titulo/Titulo";
import { AlertaInterface } from "../../../interfaces/AlertaInterface";

//estilos
const classes = require("./AlertasDetalle.module.css").default;

const AlertasDetalle = () => {
  const { idAlerta } = useParams();
  const { sendRequest } = useFetch();
  const [alerta, setAlerta] = useState<AlertaInterface>();

  //Formulario
  const {
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  //Función que trata el detalle de usuario.
  const transformData = useCallback(
    (variable: any) => {
      setAlerta(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el usuario a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/alerta/${idAlerta}?includeActivo[eq]=true&includeUsuario[eq]=true&includeIncidencia[eq]=true`,
      },
      transformData
    );
  }, [idAlerta, sendRequest, transformData]);

  return (
    <>
      <Titulo texto="Ficha de alerta" tipo="cliente" />
      <Container className="mb-3">
        <Row className="mb-3">
          <div className="col-6">
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Descripción
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={alerta?.descripcion}
                  as="textarea"
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Fecha
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={alerta?.fecha}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Activo
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control disabled value={alerta?.activo?.nombre} />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Marca
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control disabled value={alerta?.activo?.marca} />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Serie
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control disabled value={alerta?.activo?.serie} />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Incidencia
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control disabled value={alerta?.incidencia?.nombre} />
              </div>
            </Form.Group>
          </div>
          <div className="col-6">
            <Row className="mb-3">
              <h2 className={classes.subtitulo}>Usuario</h2>
            </Row>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Nombre
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control
                  disabled
                  value={`${alerta?.usuario?.nombre} ${alerta?.usuario?.apellido_paterno}`}
                />
              </div>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label
                className={`col-sm-3 col-form-label ${classes.labelBackground}`}
              >
                Correo
              </Form.Label>
              <div className="col-sm-9">
                <Form.Control disabled value={alerta?.usuario?.correo} />
              </div>
            </Form.Group>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AlertasDetalle;
