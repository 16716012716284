/**
 * Screen Home
 * @description: Es la pantalla principal de la aplicación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */
import { Accordion, Container } from "react-bootstrap";

//components
import SucursalCard from "../../SucursalCard/SucursalCard";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { SucursalInterface } from "../../../interfaces/SucursalInterface";
import { useStaleData } from "../../../hooks/useStaleData";
import SearchInput from "../../SearchInput/SearchInput";

const MonitoreoSucursales = () => {
  const [bodegas, setBodegas] = useState<SucursalInterface[]>([]);
  const [sucursalesFiltradas, setSucursalesFiltradas] = useState<
    SucursalInterface[]
  >([]);
  const { sendRequest } = useFetch();
  const { data: sucursalesConAlertas, refetch } = useStaleData(
    "/monitoreo",
    "sucursalesConAlertas"
  );
  const [term, setTerm] = useState("");

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setBodegas(variable.data);
  }, []);

  //Timeout que recupera las alertas cada minuto
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      refetch();
    }, 60000);

    return () => {
      //Eliminar el timeout cuando el component se desmonta
      clearTimeout(timeoutID);
    };
  }, [refetch]);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/sucursal?includeEstado=true&&tipo_sucursal_id[eq]=2&&estatus[eq]=true",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  //Se hace el filtrado de acuerdo al nombre del cliente.
  useEffect(() => {
    if (bodegas) {
      if (term.length === 0) {
        return setSucursalesFiltradas(bodegas);
      }
      const sucursalByNombre = bodegas.filter((bodega: SucursalInterface) =>
        bodega.nombre.toLowerCase().includes(term.toLowerCase())
      );
      if (sucursalByNombre) {
        setSucursalesFiltradas(sucursalByNombre);
      }
    }
  }, [term, bodegas]);

  return (
    <div style={{ marginTop: "10px" }}>
      <Container>
        <h2>Listado de Tiendas</h2>

        {/** Filtrado de Tiendas */}
        <SearchInput onDebounce={(value) => setTerm(value)} />

        <Accordion>
          {sucursalesFiltradas.map((bodega) => (
            <SucursalCard
              key={bodega.id}
              eventKey={bodega.id.toString()}
              sucursal={bodega}
              sucursalesConAlertas={sucursalesConAlertas}
              refetch={refetch}
            />
          ))}
        </Accordion>
      </Container>
    </div>
  );
};

export default MonitoreoSucursales;
